.embla {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex: 0 0 auto;
  padding: 10px;
  width: 154px; /* Lebar setiap slide sama dengan lebar gambar */
}
